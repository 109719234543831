import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './page/home'; // Renamed 'homepage' to 'Homepage'

function NotFound() {
  return (
    <>
      {console.error('404 - Not Found')} {/* Log to the console */}
      <script type="application/json">
        {JSON.stringify({ error: '404 - Not Found', message: 'The requested resource does not exist.' })}
      </script>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
